"use client";
import { useState, useRef } from 'react'
import { Navbar, NavbarContent, NavbarMenu, NavbarMenuToggle, NavbarBrand, NavbarItem, NavbarMenuItem } from "@nextui-org/navbar";
import { DropdownItem, DropdownTrigger, Dropdown, DropdownMenu } from "@nextui-org/react";
import { Button } from "@nextui-org/button";
import { Link } from "@nextui-org/link";
import NextLink from "next/link";

import { LogoWithOutIcon } from "./icons";
import { Divider } from "@nextui-org/divider";

import { ChevronDown, AboutIcon, ContactIcon, TeamIcon, CareerIcon } from "./icons";
import { useLocale } from 'next-intl';

import LanguageSwitcher from "./language_switcher";

export default function NavigationBar() {
  const navbarToggleRef = useRef();
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);

  const handleSideMenu = () => {
    if (isSideMenuOpen && navbarToggleRef.current) {
      navbarToggleRef.current.click();
    }
  };
  const locale = useLocale();
  const loadFAQData = (locale) => {
    try {
        const context = require.context('../messages', true, /\.json$/);
        const data = context(`./${locale}/Menu.json`);
        return data;
    } catch (error) {
        return null;
    }
  };

  const data = loadFAQData(locale);

  const icons = {
    chevron: <ChevronDown fill="currentColor" size={16} />,
    about_us: <AboutIcon className="text-black dark:text-white me-2" fill="currentColor" size={25}/>,
    contact_us: <ContactIcon className="text-black dark:text-white me-2" fill="currentColor" size={25}/>,
    our_team: <TeamIcon className="text-black dark:text-white me-2" fill="currentColor" size={25}/>,
    career: <CareerIcon className="text-black dark:text-white me-2" fill="currentColor" size={25}/>,
  };

  return (
    <>
      <Navbar className="flex md:hidden justify-between items-center" position="sticky">
        <NavbarContent as="div" className="md:hidden basis-1" justify="start">
          <LanguageSwitcher />
        </NavbarContent>

        <NavbarBrand>
          <NextLink href={locale === "en" ? "/" : "/tr" } title="PellaGlobal.net - Digital Marketing Agency in Antalya" onClick={handleSideMenu} prefetch={true}>
            <LogoWithOutIcon width={150} className="mt-2"/>
          </NextLink>
        </NavbarBrand>
        
        <NavbarContent as="div" className="md:hidden basis-1" justify="end">
          <NavbarMenuToggle ref={navbarToggleRef} onChange={(isSelected) => setIsSideMenuOpen(isSelected)}/>
        </NavbarContent>
        
        <NavbarMenu>
          <div className="mx-4 mt-2 flex flex-col gap-2 space-y-4">
            {data.Menu.map((item, index) => (
              item.dropdown && item.dropdown.length > 0 ? (
                <details className="group [&_summary::-webkit-details-marker]:hidden" key={index}>
                  <summary>
                    <NavbarMenuItem className="flex justify-between">
                      <div color="foreground">{item.label}</div>
                      <span className="shrink-0 transition duration-300 group-open:-rotate-180 mt-1">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                        </svg>
                      </span>
                    </NavbarMenuItem>
                  </summary>
                  <ul className="space-y-2 ps-6 py-3">
                    {item.dropdown.map((dropdownItem, index) => (
                      <li key={index}>
                        <NextLink href={dropdownItem.href} title={dropdownItem.title} className="opacity-70 hover:opacity-100" onClick={handleSideMenu} prefetch={true}>
                          <strong className="text-[16px] mb-4">{dropdownItem.title}</strong>
                          <p className="text-[14px] leading-[18px] text-wrap">{dropdownItem.description}</p>
                        </NextLink>
                      </li>
                    ))}
                  </ul>
                </details>
              ) : (
                <NavbarMenuItem key={item.href}>
                  <Link size="lg" href={item.href} title={item.title} onPress={handleSideMenu} prefetch={true}>
                    {item.label}
                  </Link>
                </NavbarMenuItem>
              )
            ))}
            <NavbarMenuItem>
              <Link href={data.GetQuote.href} size="lg" title={data.GetQuote.title} onPress={handleSideMenu} prefetch={true}>
                {data.GetQuote.title}
              </Link>
            </NavbarMenuItem>
          </div>
          <Divider className="my-4" />
        </NavbarMenu>
      </Navbar>
      
      <Navbar className="hidden md:flex gap-4" maxWidth="2xl" position="sticky">
        <NavbarBrand>
          <NextLink href={locale === "en" ? "/" : "/tr" } title="PellaGlobal.net - Digital Marketing Agency in Antalya" prefetch={true}>
            <LogoWithOutIcon width={200}/>
          </NextLink>
        </NavbarBrand>

        <NavbarContent className="hidden md:flex gap-10" justify="center">
          {data.Menu.map((item, index) => (
            item.dropdown && item.dropdown.length > 0 ? (
              <Dropdown 
                key={index}
                showArrow
                classNames={{
                  base: "before:bg-default-200",
                  content: "py-1 px-1 border border-default-200 bg-gradient-to-br from-white to-default-200 dark:from-default-50 dark:to-black",
                }}
              >
                <NavbarItem>
                  <DropdownTrigger>
                    <NextLink href="#" title={item.label} className="text-[15px] font-normal dark:font-extralight opacity-70 hover:opacity-100 light:opacity-85 flex justify-between" radius="sm" variant="light" prefetch={true}>{item.label} <div className="mt-1 ms-2">{icons.chevron}</div></NextLink>
                  </DropdownTrigger>
                </NavbarItem>
                <DropdownMenu aria-label="" className="w-[340px]" itemClasses={{ base: "gap-3" }}>
                  {item.dropdown.map((dropdownItem, index) => (
                    <DropdownItem key={index} description="" href={dropdownItem.href} startContent={icons[dropdownItem.icon]} prefetch={true}>
                      <div className="flex flex-wrap">
                        {dropdownItem.title}<br />
                        <p className="text-[#959595] text-wrap text-[12px] leading-4 group-hover:text-white">{dropdownItem.description}</p>
                      </div>
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            ) : (
              <NavbarItem key={index}>
                <NextLink className="text-[15px] opacity-70 hover:opacity-100" color="foreground" href={item.href} title={item.title} prefetch={true}>
                  {item.label}
                </NextLink>
              </NavbarItem>
            )
          ))}
        </NavbarContent>

        <NavbarContent className="hidden md:flex flex-1 w-full basis-1/5 sm:basis-full" justify="end">
          <NavbarItem className="flex gap-4">
            <LanguageSwitcher />
          </NavbarItem>
          <NavbarItem className="hidden lg:flex">
            <Button as={Link} className="text-sm font-normal text-default-600 bg-default-100 dark:text-black dark:bg-white rounded-full px-6" href={data.GetQuote.href} title={data.GetQuote.title}>{data.GetQuote.title}</Button>
          </NavbarItem>
        </NavbarContent>

        <NavbarContent className="md:hidden basis-1 pl-4" justify="end">
          <NavbarMenuToggle />
        </NavbarContent>
      </Navbar>
    </>
  );
};